

























































































import Vue from "vue"
import { mapGetters } from "vuex"
import StarRating from "vue-star-rating"
import { RtbButton } from "@/components/ui"
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"
import PlyrWrap from "@/components/GroupTeams/Common/Games/GameCardParts/PlyrWrap.vue"
import VideoComponent from "@/components/ui/VideoComponent/VideoComponent.vue"
import User from "@shared/User"
import MissionType from "@shared/enums/Mission"
import isTeamMision from "@shared/helpers/isTeamMission"
import { MEDIA_MISSION_TYPES } from "./Voting.consts"
import File from "@/services/file.service"
import MissionCollection from "@shared/MissionCollection"

export default Vue.extend({
  name: "VotingCard",

  // @ts-ignore TODO
  components: {
    RtbButton,
    RatioContainer,
    StarRating,
    PlyrWrap,
    VideoComponent
  },
  props: {
    ratio: {
      type: String,
      default: undefined
    },
    isVideo: {
      type: Boolean,
      default: undefined
    },
    isGameOver: {
      type: Boolean,
      default: undefined
    },
    isVideoControls: {
      type: Boolean,
      default: undefined
    },
    data: {
      type: Object,
      default: undefined
    },
    mission: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    ...mapGetters(["user", "gameStatus", "isDIYGame"]),
    ...mapGetters("auth", ["isHost"]),
    label() {
      return this.isTeamName
        ? this.data?.team
        : User.getShortenedName(this.data)
    },
    isOwner() {
      return this.user.id === this.data.userID
    },
    missionType() {
      return this.mission?.behavior
    },
    isScoreVisible() {
      return this.isHost || !this.mission?.hidePointsInVoting
    },
    personalDownloadOnly() {
      return (
        this.missionType === MissionType.UnconditionalPositiveRegard ||
        this.missionType === MissionType.SpiralDrawing
      )
    },
    isMediaVoting() {
      return MEDIA_MISSION_TYPES.includes(this.missionType)
    },
    caption() {
      return this.data?.caption
    },
    correct() {
      return this.data?.correct
    },
    score() {
      return this.data?.score
    },
    showNames() {
      return this.isGameOver || !this.gameStatus?.votingAnon
    },
    getMedia() {
      const { correct } = this.data
      return Array.isArray(correct) ? correct?.[0] : correct
    },
    imageSrc() {
      if (!this.isOwner && this.personalDownloadOnly)
        return decodeURI(this.getMedia?.encrypted ?? this.getMedia?.image)

      return decodeURI(this.getMedia?.image)
    },
    videoSrc() {
      return this.getMedia?.video
    },
    showRating() {
      if (this.isGameOver) {
        return false
      }

      if (this.isHost && this.data.correct) {
        return true
      }

      if (this.mission && !this.mission.noVote) {
        if (this.isTeamMission) {
          return this.data.correct && this.data.teamID !== this.user?.teamID
        }
        return this.data.correct && this.data.userID !== this.user.id
      }

      return false
    },
    isTeamName() {
      return this.data.behavior === MissionType.DrawingEachTeam
    },
    isTeamMission() {
      return isTeamMision(this.playStep || this.mission)
    },
    playStep() {
      return MissionCollection.normalize(this.$store.getters.missions).find(
        mission =>
          mission.activityId === this.mission.activityId && mission.play
      )
    }
  },
  methods: {
    async download() {
      File.download(await (await fetch(this.imageSrc)).blob(), "picture.png")
    }
  }
})
