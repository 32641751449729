var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g({ staticClass: "voting-card" }, _vm.$listeners),
    [
      _c(
        "transition",
        { attrs: { name: "voting-card__transition", mode: "out-in" } },
        [
          _vm.showNames
            ? _c(
                "v-flex",
                [
                  _c(
                    "v-flex",
                    { staticClass: "voting-card__title" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "voting-card__title-icon",
                          attrs: { dark: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.data.teamIcon) + " ")]
                      ),
                      _c("span", { staticClass: "voting-card__title-text" }, [
                        _vm._v(" " + _vm._s(_vm.label) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "voting-card__media" },
        [
          _c("RatioContainer", { attrs: { ratio: _vm.ratio } }, [
            !!_vm.correct
              ? _c(
                  "div",
                  { staticClass: "voting-card__media-content" },
                  [
                    _vm.isMediaVoting
                      ? [
                          _c(
                            "div",
                            { staticClass: "voting-card__media-cnt" },
                            [
                              _vm.isVideo
                                ? _c("PlyrWrap", {
                                    attrs: { controls: _vm.isVideoControls },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var config = ref.config
                                            return [
                                              _vm.isVideo && _vm.videoSrc
                                                ? _c("VideoComponent", {
                                                    ref: "videoMedia",
                                                    attrs: {
                                                      src: _vm.videoSrc,
                                                      controls:
                                                        _vm.isVideoControls,
                                                      "data-plyr-config":
                                                        config,
                                                      autoplay: "",
                                                      muted: "",
                                                      loop: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3195182741
                                    ),
                                  })
                                : _c("img", { attrs: { src: _vm.imageSrc } }),
                            ],
                            1
                          ),
                          !!_vm.caption && !_vm.isDIYGame
                            ? _c(
                                "div",
                                { staticClass: "voting-card__media-caption" },
                                [_vm._v(" " + _vm._s(_vm.caption) + " ")]
                              )
                            : _vm._e(),
                        ]
                      : _c("div", { staticClass: "text-white px-3" }, [
                          _vm._v(" " + _vm._s(_vm.correct) + " "),
                        ]),
                  ],
                  2
                )
              : _c("div", { staticClass: "voting-card__waiting" }, [
                  _vm._v("Waiting..."),
                ]),
          ]),
        ],
        1
      ),
      !_vm.personalDownloadOnly
        ? _c(
            "div",
            { staticClass: "voting-card__rating" },
            [
              _c(
                "span",
                { staticClass: "voting-card__rating-title" },
                [
                  !!_vm.mission && !!_vm.mission.noVote && _vm.isHost
                    ? [_vm._v(" Host ")]
                    : _vm._e(),
                  _vm.showRating ? [_vm._v("Vote ")] : _vm._e(),
                ],
                2
              ),
              _vm.showRating
                ? _c("StarRating", {
                    staticClass: "voting-card__stars",
                    attrs: {
                      "read-only": false,
                      increment: 0.5,
                      padding: 1,
                      "star-size": 20,
                      "show-rating": false,
                      "border-width": 0,
                      "inactive-color": _vm.$theme.get("--disabled-color"),
                      "active-color": "#fff",
                      "border-color": "transparent",
                    },
                    on: {
                      "rating-selected": function ($event) {
                        return _vm.$emit("rated", {
                          rate: $event,
                          data: _vm.data,
                        })
                      },
                    },
                  })
                : _vm._e(),
              _vm.isScoreVisible
                ? _c("span", { staticClass: "voting-card__points" }, [
                    _vm._v(" " + _vm._s(_vm.score || 0) + " PTS "),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm.isOwner
        ? _c(
            "RtbButton",
            {
              staticClass: "mt-2",
              attrs: { size: "sm" },
              on: { click: _vm.download },
            },
            [
              _c("svg-icon", {
                attrs: { name: "cloud-download-regular", size: "small" },
              }),
              _vm._v(" Download "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }